import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import routes from "../routes";
import './DefaultLayout.scss';

/*-- Imports for redux --*/
import { useSelector } from 'react-redux';
import { RootState, useReduxDispatch } from '../redux/store';
import { updateResponsive, resetResponsive } from '../redux/responsive/responsiveAction';

/*-- Import dependencies --*/
import LoadingScreen from '../components/common/loadingScreen/LoadingScreen';

import { responsiveScreenSizesEnum } from '../utilities/enums';
import DefaultHeader from './DefaultHeader';
import 'devextreme/dist/css/dx.light.css';

function DefaultLayout() {
    const showLoadingScreen = useSelector((state: RootState) => state.loadingScreen.show);
    const dispatch = useReduxDispatch();
    const userSessionData = useSelector((state: RootState) => state.UserSession);
    const Login = React.lazy(() => import('../pages/Login/Login'));

    useEffect(() => {
        responsiveFn();
    });

    function loadingScreen() {
        return <LoadingScreen show={true} />
    }

    function responsiveFn(): any {
        if (window.outerWidth <= responsiveScreenSizesEnum.mobileSmallScreen) {
            let screen = { isTabScreen: false, isMobileLargeScreen: false, isMobileSmallScreen: true }
            dispatch(updateResponsive(screen));
        } else if (window.outerWidth <= responsiveScreenSizesEnum.mobileLargeScreen) {
            let screen = { isTabScreen: false, isMobileLargeScreen: true, isMobileSmallScreen: false }
            dispatch(updateResponsive(screen));
        } else if (window.outerWidth <= responsiveScreenSizesEnum.tabScreenSize) {
            let screen = { isTabScreen: true, isMobileLargeScreen: false, isMobileSmallScreen: false }
            dispatch(updateResponsive(screen));
        } else {
            dispatch(resetResponsive());
        }
    }

    return (
        <React.Fragment>
            <div className='header'>
                <DefaultHeader />
            </div>
            <div className='outer'>
                <BrowserRouter>
                    <div id="default-page-load-area" className='default_page_close'>
                        {/* style={{paddingLeft:"220px",paddingRight:"1%",paddingTop:"1%",paddingBottom:"1px"}} */}
                        <Suspense fallback={loadingScreen()}>
                            <Switch>
                                {routes.map((route, index) => {
                                    return route.component ? (
                                        <Route key={index} path={route.path} component={route.component} />
                                    ) : (null);
                                })}
                                <Redirect from="/" to="/dashboard" />
                            </Switch>
                        </Suspense>
                    </div>
                </BrowserRouter>
            </div>    
            <LoadingScreen show={showLoadingScreen} />
        </React.Fragment>
    )
}

export default DefaultLayout;