import { UserSessionActionsTypes } from "./UserSession_Actions";

export interface UserSessionState {
    userId: number;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
};

const initialState: UserSessionState = {
    userId: 0,
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
};

export const UserSession_Reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UserSessionActionsTypes.AddUserSessionType:
            return {
                ...state,
                userId: action.payload.userId,
                userName: action.payload.userName,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                mobile: action.payload.mobile
            };
        case UserSessionActionsTypes.AddGrantedMenuType:
            return {
                ...state,
            };
        case UserSessionActionsTypes.AddGrantedAppsType:
            return {
                ...state,
            };
        case UserSessionActionsTypes.AddGrantedLocationsType:
            return {
                ...state,
            };
        case UserSessionActionsTypes.ResetUserSessionType:
            return initialState;
        default:
            return state;
    }
};