import { useState, useEffect, useRef } from 'react';
import './DefaultHeader.scss';

/**-- Imports for redux --*/
import { useSelector } from 'react-redux';
import { RootState, useReduxDispatch } from '../redux/store';

import { addAzureConfiguration } from '../redux/azureConfiguration/AzureConfiguration_Actions';
import { resetUserSession } from '../redux/userSession/UserSession_Actions';

/*-- Import dependencies --*/
import userImg from '../assets/images/avatars/user.jpg';
import { get, post } from '../utilities/apiClient';
// import DefaultBreadcrumb from './DefaultBreadcrumb';

function DefaultHeader() {
    const dispatch = useReduxDispatch();
    const userSessionData = useSelector((state: RootState) => state.UserSession);

    let navMenuNode: any = useRef(null);
    let userMenuNode: any = useRef(null);
    let grantedAppMenuNode: any = useRef(null);

    const [navMenuVisible, setNavMenuVisible] = useState(true);
    const [userMenuVisible, setUserMenuVisible] = useState(true);
    const [grantedAppMenuVisible, setGrantedAppMenuVisible] = useState(true);
    const [currentRoutePath, setCurrentRoutePath] = useState("");
    const azureConfig = useSelector((state: RootState) => state.AzureConfiguration);

    const [menuItems, setMenuItems] = useState([{
        screenId: 0,
        screenName: "",
        url: "",
        screenParentId: 0,
        icon: "",
    }]);

    useEffect(() => {
        getMenuItems();
        // checkMenuFunctions();
        // if ((azureConfig.blobStorageAccount == "") || (azureConfig.blobStorageBaseUrl == "") || (azureConfig.blobStorageSASUrl == "")) {
        //     getAzureConfiguration();
        // }
        // add when mounted
        document.addEventListener("mousedown", handleNavMenuOutsideClick);
        document.addEventListener("mousedown", handleUserMenuOutsideClick);
        document.addEventListener("mousedown", handleGrantedAppMenuOutsideClick);

        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleNavMenuOutsideClick);
            document.removeEventListener("mousedown", handleUserMenuOutsideClick);
            document.removeEventListener("mousedown", handleGrantedAppMenuOutsideClick);
        };



    }, []);

    const onLogout = () => {
        post('User/UserSessionLogout', {}, true).then((response: any) => {
            if (response.data.statusCode === 200) {
                dispatch(resetUserSession());
            }
        });
    };

    const getMenuItems = () => {
        get("Screen/GetSideMenuItems", {}, false)
            .then(res => {
                var response: any = res;
                if (response.data.statusCode === 200) {
                    setMenuItems(response.data.result);
                }
                else {
                    console.log("Menu Items :-", response.data.statusCode)
                }
            });
    }

    const handleNavMenuOutsideClick = (event: any) => {
        if (event !== null && event.target !== null && event.target.id !== null) {
            if (event.target.id === "main_menu_toggler_icon") {
                setNavMenuVisible(prevState => !prevState);
            } else {
                if (navMenuNode && navMenuNode.current) {
                    if (navMenuNode.current.contains(event.target)) {
                        return;
                    }
                    setNavMenuVisible(true);
                }
            }
        }
    };

    const handleGrantedAppMenuOutsideClick = (event: any) => {
        if (event !== null && event.target !== null && event.target.id !== null) {
            if (event.target.id === "granted_app_list_toggler_id") {
                setGrantedAppMenuVisible(prevState => !prevState);
            } else {
                if (grantedAppMenuNode && grantedAppMenuNode.current) {
                    if (grantedAppMenuNode.current.contains(event.target)) {
                        return;
                    }
                    setGrantedAppMenuVisible(true);
                }
            }
        }
    };

    useEffect(() => {
        if (navMenuVisible) {
            let sideMenu = document.getElementById("default_sidebar_id");
            sideMenu != null && sideMenu.classList.replace('sidebar-menu-close', 'sidebar-menu-open');

            let sideMenu1 = document.getElementById("default-page-load-area");
            sideMenu1 != null && sideMenu1.classList.replace('default_page_open', 'default_page_close');

        } else {
            let sideMenu = document.getElementById("default_sidebar_id");
            sideMenu != null && sideMenu.classList.replace('sidebar-menu-open', 'sidebar-menu-close');

            let sideMenu1 = document.getElementById("default-page-load-area");
            sideMenu1 != null && sideMenu1.classList.replace('default_page_close', 'default_page_open');
        }
    }, [navMenuVisible]);

    useEffect(() => {
        if (!grantedAppMenuVisible) {
            let appMenu = document.getElementById("default_granted_app_menu_id");
            if (appMenu != null) {
                appMenu.style.display = "block";
            }
        } else {
            let appMenu = document.getElementById("default_granted_app_menu_id");
            if (appMenu != null) {
                appMenu.style.display = "none";
            }
        }
    }, [grantedAppMenuVisible]);

    const handleUserMenuOutsideClick = (event: any) => {
        if (event !== null && event.target !== null && event.target.id !== null) {
            if (event.target.id === "user_menu_toggler_id") {
                setUserMenuVisible(prevState => !prevState);
            } else {
                if (userMenuNode && userMenuNode.current) {
                    if (userMenuNode.current.contains(event.target)) {
                        return;
                    }
                    setUserMenuVisible(true);
                }
            }
        }
    };

    useEffect(() => {
        if (!userMenuVisible) {
            let userPopup = document.getElementById("default_user_menu_id");
            if (userPopup != null) {
                userPopup.style.display = "block";
            }
        } else {
            let userPopup = document.getElementById("default_user_menu_id");
            if (userPopup != null) {
                userPopup.style.display = "none";
            }
        }
    }, [userMenuVisible]);

    const setBreadcrumb = (routePath: string) => {
        setCurrentRoutePath(routePath);
    };

    const resetBreadcrumb = () => {
        setCurrentRoutePath("");
    };

    return (
        <div>
            <header className="default-header">
                {/*-- Header logo & title container --*/}
                <div className="default-header-logo-container">
                    <a href={window.location.origin + "/dashboard"}>
                        <img src="https://spark.keellssuper.net/static/media/logo.e981cb26.jpg" alt="Keells Logo" />
                    </a>
                    <div id="main_menu_toggler_id">
                        <i id="main_menu_toggler_icon" className="fas fa-bars"></i>
                    </div>
                </div>
                {/*-- Header user menu & granted application menu toggle container --*/}
                <div id="default_header_user_container">
                    <img id="user_menu_toggler_id" src={userImg} className="user-menu-toggler" alt="User_toggler" />
                </div>
                {/*-- User menu --*/}
                <div id="default_user_menu_id" ref={userMenuNode}>
                    <div className="user-menu-image-container">
                        <img src={userImg} alt="User" />
                    </div>
                    <div className="user-menu-full-name">{userSessionData.firstName + " " + userSessionData.lastName}</div>
                    <div className="user-menu-logout-btn" onClick={() => onLogout()}>
                        <i className="fas fa-power-off" style={{ marginRight: "8px", fontSize: "12px" }} />Logout</div>
                </div>
                
                {/*-- Main menu --*/}
                <div id="default_sidebar_id" className="sidebar-menu-open" ref={navMenuNode}>
                    <div className="default-sidebar-navbar">
                        <nav className="nav-item">
                            {menuItems.map((menuItem) => (
                                <li className='nav-item-list' key={menuItem.screenId}>
                                    <a style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <i className={menuItem.icon} />
                                        <label
                                            className="nav-title"
                                            style={{ marginLeft: 5, width: '100%' }}
                                            htmlFor={menuItem.screenName}
                                            onClick={() => { window.location.href = menuItem.url }}
                                        >
                                            {menuItem.screenName}
                                        </label>
                                    </a>
                                </li>))}
                        </nav>
                    </div>
                </div >
            </header >
        </div >
    )
}

export default DefaultHeader;