import React from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoadingScreen from './components/common/loadingScreen/LoadingScreen';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import "./assets/scss/defaultTheme.scss";
import DefaultLayout from './defaultLayout/DefaultLayout';
import Login from './pages/Login/Login';
import MessageBox from './components/common/messageBox/MessageBox';
import 'devextreme/dist/css/dx.light.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    // const Login = React.lazy(() => import('./pages/Login/Login'));
    const showLoadingScreen = useSelector((state: RootState) => state.loadingScreen.show);
    const userSessionData = useSelector((state: RootState) => state.UserSession);

    const loadingScreen = () => {
        return <LoadingScreen show={true} />
    }

    if (userSessionData.userId == 0) {
        return <Login />
    }

    return (
        <div className="App">
            <BrowserRouter>
                <React.Suspense fallback={loadingScreen()}>
                    <Switch>
                        {/* <Route exact path="/" render={() => <Login />} /> */}
                        <Route path="/" component={DefaultLayout} />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
            <MessageBox />
            <LoadingScreen show={showLoadingScreen} />
        </div>
    );
}

export default App;
