import React from "react";
import "./LoadingScreen.css";
import Loader from "./Loader";


interface iLoadingScreen {
    show: boolean
}

export default function LoadingScreen(props: iLoadingScreen) {
    const show = props.show;
    return (
        <React.Fragment>
            {show ?
                <div style={show ? { display: ''} : { display: 'none' }} className="full-width-div">
                <Loader />
                </div>
                : null}
        </React.Fragment>
    )
}