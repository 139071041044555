import React from 'react';

interface iroute {
    path: string
    name: string,
    component: any
}

const HomePage = React.lazy(() => import("./pages/homePage/HomePage"));
const BannerUpload = React.lazy(() => import("./pages/bannerUpload/BannerUpload"));
const Ordering = React.lazy(() => import("./pages/Ordering/Ordering"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/CustomerDashboard"));
const SavedOrders = React.lazy(() => import("./pages/SavedOrders/SavedOrders"));


const routes: iroute[] = [
    { path: "/home", name: "Home", component: HomePage },
    { path: "/bannerUpload", name: "BannerUpload", component: BannerUpload }, 
    { path: "/ordering", name: "Ordering", component: Ordering }, 
    { path: "/dashboard", name: "Dashboard", component: Dashboard }, 
    { path: "/savedOrders", name: "Saved Orders", component: SavedOrders }, 
]

export default routes;